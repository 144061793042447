.header {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  background-color: #d6c2e8;
  display: grid;
  grid-auto-columns: 20px;
  grid-auto-rows: 20px;
  height: 850px;
}

.polygon-1 {
  width: 1100px;
  height: 1800px;
  background-image: url(../images/purple-poly.png);
  grid-row-start: 1;
  grid-column-start: 14;
  margin-top: -400px;
}

.polygon-2 {
  width: 350px;
  height: 350px;
  grid-row-start: 17;
  grid-column-start: 8;
  z-index: 1;
  background-color: #fed54d;
  transform: rotate(-45.59deg);
}

h1 {
  color: #fff;
  font-size: 120px;
  text-shadow: 0px 4px 4px rgba(38, 44, 101, 0.75);
  font-family: "Lobster", "Oleo Script", cursive;
  margin: -10px 40px 0 40px;
  text-align: inherit;
  overflow-anchor: top;
}

.laurels {
  filter: drop-shadow(0px 10px 4px rgba(255, 69, 60, 0.75));
  background-color: #9f94ee;
  border-radius: 200px;
}

.developer {
  grid-column-start: 34;
  grid-column-end: 65;
  z-index: 4;
  grid-row-start: 30;
  grid-row-end: 39;
  display: flex;
  justify-content: left;
  align-items: center;
}

.artist {
  grid-column-start: 1;
  grid-row-start: 7;
  grid-column-end: 18;
  grid-row-end: 16;
  z-index: 2;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: -100px;
}

.purple-benzi {
  height: 800px;
  width: auto;

  grid-column-start: 13;
  grid-row-start: 5;
  z-index: 2;
}

.orange-benzi {
  height: 800px;
  width: auto;

  grid-column-start: 15;
  grid-row-start: 5;
  z-index: 3;
}

.hh2 {
  font-family: "Kodchasan", sans-serif;
  font-size: 40px;
  line-height: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.75);
  z-index: 3;
  color: #fff;
}

.tagline {
  grid-column-start: 40;
  grid-column-end: 58;
  grid-row-start: 12;
}

.tagline:hover {
  animation: revealShadows 5s forwards;
}

@keyframes revealShadows {
  0% {
    filter: drop-shadow(none);
  }
  5% {
    filter: drop-shadow(2px -2px #ff453c);
  }
  10% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd);
  }
  15% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd)
      drop-shadow(6px -6px #fe964a);
  }
  20% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd)
      drop-shadow(6px -6px #fe964a) drop-shadow(8px -8px #fed54d);
  }
  25% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd)
      drop-shadow(6px -6px #fe964a) drop-shadow(8px -8px #fed54d)
      drop-shadow(10px -10px #aba0e6);
  }
  75% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd)
      drop-shadow(6px -6px #fe964a) drop-shadow(8px -8px #fed54d)
      drop-shadow(10px -10px #aba0e6);
  }
  80% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd)
      drop-shadow(6px -6px #fe964a) drop-shadow(8px -8px #fed54d);
  }
  85% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd)
      drop-shadow(6px -6px #fe964a);
  }
  90% {
    filter: drop-shadow(2px -2px #ff453c) drop-shadow(4px -4px #ff13cd);
  }
  95% {
    filter: drop-shadow(2px -2px #ff453c);
  }

  100% {
    filter: drop-shadow(none);
  }
}

.contact-button {
  grid-column-start: 45;
  grid-row-start: 2;
}

.button-text {
  font-family: "Kodchasan", sans-serif;
  font-size: 30px;
  color: #262c65;
  text-shadow: 0px 4px 4px rgba(38, 44, 101, 0.25);
  background-color: #fff;
  padding: 25px;
  border-radius: 100px;
  border: none;
  filter: drop-shadow(0px 10px 4px rgba(255, 33, 58, 0.75));
  z-index: 3;
  font-weight: 700;
  width: 300px;
  transition: 0.3s;
  cursor: pointer;
}

.button-text:hover {
  color: #fff;
  background-color: rgba(255, 69, 60, 1);
  filter: drop-shadow(0px 4px 4px rgba(255, 33, 58, 0.75));
  transition: 0.3s;
}

.button-symbol {
  display: none;
}
@media (max-width: 1200px) {
  .header {
    width: 900px;
  }

  .hh1 {
    font-size: 90px;
    height: 70px;
    margin: -20px 40px 0 40px;
  }

  .developer {
    grid-column-start: 26;
  }

  .artist {
    grid-column-end: 14;
  }

  .purple-benzi {
    grid-column-start: 4;
  }

  .orange-benzi {
    grid-column-start: 8;
  }

  .polygon-2 {
    width: 300px;
    height: 300px;
    grid-row-start: 10;
    grid-column-start: 3;
  }

  .hh2 {
    font-size: 30px;
  }

  .tagline {
    grid-column-start: 33;
    grid-column-end: 44;
    grid-row-start: 12;
  }

  .contact-button {
    grid-column-start: 35;
    z-index: 4;
  }

  .button-text {
    font-size: 20px;

    padding: 20px;
    border-radius: 100px;

    width: 200px;
  }

  .polygon-1 {
    width: 1100px;
    height: 1800px;
    background-image: url(../images/purple-poly.png);
    grid-row-start: 1;
    grid-column-start: 7;
    margin-top: -400px;
  }
}

@media (max-width: 900px) {
  .header {
    width: 600px;
    height: 550px;
  }

  .hh1 {
    font-size: 70px;
    height: 55px;
  }

  .developer {
    grid-column-start: 14;
    grid-row-start: 20;
    grid-row-end: 25;
  }

  .artist {
    grid-column-end: 12;
    grid-row-start: 6;
    grid-row-end: 11;
  }

  .purple-benzi {
    grid-column-start: 5;
    height: 500px;
  }

  .orange-benzi {
    grid-column-start: 7;
    height: 500px;
  }

  .polygon-2 {
    width: 200px;
    height: 200px;
    grid-row-start: 9;
    grid-column-start: 4;
  }

  .hh2 {
    font-size: 20px;
  }

  .tagline {
    grid-column-start: 22;
    grid-column-end: 29;
    grid-row-start: 10;
  }

  .contact-button {
    grid-column-start: 20;
    z-index: 4;
  }

  .polygon-1 {
    grid-row-start: 1;
    grid-column-start: 1;
    margin-top: -450px;
  }
}

@media (max-width: 600px) {
  .header {
    width: 300px;
    height: 450px;
  }

  .hh1 {
    font-size: 35px;
    height: 30px;
    margin: -10px 20px 0 20px;
  }

  .developer {
    grid-column-start: 8;
    grid-row-start: 19;
    grid-row-end: 22;
  }

  .artist {
    grid-row-start: 7;
    grid-row-end: 10;
    grid-column-end: 7;
    z-index: 4;
  }

  .purple-benzi {
    grid-column-start: 1;
    height: 350px;
    grid-row-start: 7;
  }

  .orange-benzi {
    grid-column-start: 2;
    height: 350px;
    grid-row-start: 7;
  }

  .polygon-2 {
    width: 100px;
    height: 100px;
    grid-row-start: 11;
    grid-column-start: 1;
  }

  .hh2 {
    font-size: 16px;
  }

  .tagline {
    grid-column-start: 10;
    grid-column-end: 15;
    grid-row-start: 2;
  }

  .contact-button {
    grid-column-start: 1;
    grid-row-start: 21;
    z-index: 4;
  }

  .button-text {
    display: inline;
    padding: 8px;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    filter: drop-shadow(0px 10px 4px rgba(255, 33, 58, 0.75));
    z-index: 3;
    background-color: #fff;
    font-size: 8px;
    border: none;
  }

  .polygon-1 {
    margin-left: -250px;
    width: 550px;
    height: 900px;
  }
}
