.bio {
  background-image: url(../images/desert-vaporwave.png);
  background-size: cover;
  width: 1200px;
  padding: 50px 0;
  margin: 0 auto;
  background-color: #9f94ee;
  display: flex;
  flex-direction: column;
  grid-auto-columns: 20px;
  color: #262c65;
  grid-auto-rows: 20px;
}

.bio-header {
  grid-column-start: 1;
  grid-column-end: 60;
  grid-row-start: 5;
}

.bio-text {
  margin: 0 auto;
  width: 80%;
  padding: 20px;
}

@media (max-width: 1200px) {
  .bio {
    width: 900px;
  }
}
@media (max-width: 900px) {
  .bio {
    width: 600px;
  }
}
@media (max-width: 600px) {
  .bio {
    width: 300px;
  }
}
