.portfolio {
  background-color: #d6c2e8;
  width: 1200px;
  margin: 0 auto;
  color: #262c65;
  overflow: hidden;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
}

.portfolio-section-1 {
  display: flex;
  align-items: center;
  gap: 4rem;
  padding: 2rem;
  flex-direction: row;
  z-index: 1;
}
.portfolio-section-1 > div:last-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.portfolio-section-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 2rem;
  flex-direction: column;
  z-index: 1;
}
.tool {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio-section-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 2rem;
  flex-direction: row;
  align-items: flex-start;
  z-index: 1;
}

.portfolio-section-3 {
  display: flex;
  gap: 4rem;
  padding: 2rem;
  flex-direction: row-reverse;
  z-index: 1;
  align-items: center;
}
.portfolio-section-1 > div:first-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hh3 {
  font-family: "Lobster", "Oleo Script", cursive;
  text-align: center;
  font-size: 120px;
  z-index: 1;
}

.project-link {
  font-family: "Kodchasan";
  font-size: 40px;

  display: block;
  padding: 20px;
}

a {
  color: #262c65;
  text-decoration: none;
  cursor: pointer;
}

.project-link:hover {
  filter: drop-shadow(0px 4px 1px rgba(255, 69, 60, 0.75));
}

.desktop-box {
  flex: 0 0 auto;
}

.g3 {
  max-width: 500px;
  width: 100%;
  height: auto;
}

.caption {
  width: 100%;
  text-align: center;
  font-family: "Kodchasan";
  font-size: 13px;
}

.portfolio-text {
  font-family: "Kodchasan";
  font-size: 1.2rem;
  text-align: left;
  line-height: 40px;
  text-indent: 30px;
}

.text-block-1 {
  max-width: 600px;
  font-size: 1.2rem;
  margin: 50px 0;
}

.portfolio-text-block {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.hh4 {
  font-family: "Kodchasan";
  line-height: 60px;
  font-size: 40px;
}

.text-block-2 {
  max-width: 800px;
}

.text-block-3 {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tablet-box {
  max-width: 300px;
}

.ipad {
  width: 100%;
}

.text-block-4 {
  z-index: 1;
}

.tool-belt {
  display: flex;
  width: 800px;
  justify-content: space-between;
  margin: 0 auto;
  z-index: 1;
  flex-wrap: wrap;
}

.tool-icon {
  height: 50px;
  width: auto;
  margin-bottom: 20px;
  z-index: 1;
}

.text-block-5 {
  z-index: 1;
}

.phone-box {
  width: 300px;
  z-index: 1;
}

.phone {
  width: 100%;
}

.text-block-6 {
  z-index: 1;
}

.text-block-7 {
  z-index: 1;
}

.polygon-3 {
  background-image: url(../images/yellow-poly.png);
  width: 2642px;
  height: 2290px;
  z-index: 0;
  margin: -1150px;
}

.snake {
  visibility: hidden;
  width: 0;
}

@media (max-width: 1200px) {
  .portfolio {
    width: 900px;
  }

  .portfolio-section-3 {
    align-items: flex-start;
  }

  .snake {
    visibility: visible;
    width: 100%;
  }
  .hh3 {
    font-size: 100px;
  }

  .project-link {
    font-size: 30px;
  }

  .text-block-3 {
    max-width: 500px;
  }

  .desktop-box {
    width: 445px;
  }

  .hh4 {
    font-size: 30px;
  }

  .tablet-box {
    width: 350px;
  }

  .ipad {
    width: 100%;
  }

  .tool-belt {
    width: 600px;
  }

  .tool-icon {
    height: 30px;
  }
}
@media (max-width: 900px) {
  .snake {
    visibility: hidden;
    width: 0;
  }

  .portfolio-section-3 {
    align-items: center;
    flex-direction: column;
  }

  .hh3 {
    font-size: 70px;
  }
  .portfolio-section-1 {
    flex-direction: column;
  }
  .project-link {
    margin-left: 0;
  }

  .portfolio-text {
    line-height: 30px;
  }

  .portfolio-section-2 {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .portfolio {
    width: 600px;
  }

  .hh4 {
    font-size: 25px;
  }

  .tool-belt {
    width: 400px;
    gap: 30px;
  }
}
@media (max-width: 600px) {
  .hh3 {
    font-size: 30px;
  }

  .portfolio {
    width: 300px;
  }

  .desktop-box {
    width: 300px;
  }

  .hh4 {
    font-size: 20px;
    line-height: 40px;
  }

  .tool-belt {
    width: 260px;
    gap: 15px;
  }

  .tool-icon {
    height: 20px;
  }

  .phone-box {
    width: 260px;
  }

  .project-link {
    font-size: 15px;
    font-weight: 700;
  }

  .portfolio-section-1 {
    align-items: center;
  }
}
