.footer {
  background-color: #9f94ee;
  width: 1200px;
  margin: 0 auto;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Kodchasan";
  font-size: 15px;
}

@media (max-width: 1200px) {
  .footer {
    width: 900px;
  }
}
@media (max-width: 900px) {
  .footer {
    width: 600px;
  }
}
@media (max-width: 600px) {
  .footer {
    width: 300px;
  }
}
