@import url(styles/reset.css);
@import url(styles/bio.css);
@import url(styles/contact.css);
@import url(styles/footer.css);
@import url(styles/header.css);
@import url(styles/scroll.css);
@import url(styles/portfolio.css);
@import url("https://fonts.googleapis.com/css2?family=Kodchasan:wght@400;700&family=Lobster&family=Oleo+Script&display=swap");
.App {
  text-align: center;
  background-color: #262c65;
}
